import { Provider, atom, createStore, useAtom } from "jotai";
import type { PropsWithChildren } from "react";
import { useEffect, useRef } from "react";
import type { AlertType } from "../Alert";
import Alert from "../Alert";
import Snackbar from "../Snackbar";

export const NOTIFICATION_TIMEOUT = 10000;

const snackbarStore = createStore();

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  return <Provider store={snackbarStore}>{children}</Provider>;
};

export type SnackbarMessage = {
  message: string;
  severity: "success" | "warning" | "error" | "info";
  closeable?: boolean;
  autoHideDuration?: number;
};

export const snackbarAtom = atom<{
  open: boolean;
  message: string;
  autoHideDuration?: number;
  severity: AlertType;
  closeable?: boolean;
}>({
  open: false,
  message: "",
  autoHideDuration: 6000,
  severity: "success",
  closeable: true,
});

export function snackbarSuccess(message: string, closeable = true, autoHideDuration?: number) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "success",
    closeable,
    autoHideDuration,
  });
}

export function snackbarWarn(message: string, closeable = true, autoHideDuration?: number) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "warning",
    closeable,
    autoHideDuration,
  });
}

export function snackbarError(message: string, closeable = true, autoHideDuration?: number) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "error-notification",
    closeable,
    autoHideDuration,
  });
}

export function snackbarInfo(message: string, closeable = true, autoHideDuration?: number) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "info",
    closeable,
    autoHideDuration,
  });
}

export function showSnackbarMessage(details: SnackbarMessage) {
  switch (details.severity) {
    case "success":
      snackbarSuccess(details.message, details.closeable, details.autoHideDuration);
      break;
    case "warning":
      snackbarWarn(details.message, details.closeable, details.autoHideDuration);
      break;
    case "info":
      snackbarInfo(details.message, details.closeable, details.autoHideDuration);
      break;
    case "error":
      snackbarError(details.message, details.closeable, details.autoHideDuration);
      break;
  }
}

export const useSnackbar = (snackbarMessage?: SnackbarMessage) => {
  const [snackbar, setSnackbar] = useAtom(snackbarAtom);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (snackbarMessage && snackbar.open) {
      /**
       * Hide the current snackbar and then show the new one
       */
      close();
      timeoutRef.current = setTimeout(() => {
        showSnackbarMessage(snackbarMessage);
      }, 0);
    } else if (snackbarMessage) {
      /**
       * Show the snackbar because one isn't already shown
       */
      showSnackbarMessage(snackbarMessage);
    } else {
      close();
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [snackbarMessage]);

  const close = () => {
    clearTimeout(timeoutRef.current);
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return {
    success: snackbarSuccess,
    warn: snackbarWarn,
    error: snackbarError,
    close,
    isOpen: snackbar.open,
    message: snackbar.message,
    severity: snackbar.severity as AlertType,
    autoHideDuration: snackbar.autoHideDuration,
    closeable: snackbar.closeable,
  };
};

export function AppAlerts({ snackbarMessage }: { snackbarMessage?: SnackbarMessage }) {
  const snackbar = useSnackbar(snackbarMessage);

  function closeSnackbar(reason?: "timeout" | "clickaway" | "escapeKeyDown") {
    if (reason === "clickaway") {
      return;
    }

    snackbar.close();
  }

  return (
    <Snackbar
      open={snackbar.isOpen}
      onClose={(_, reason) => {
        closeSnackbar(reason);
      }}
      autoHideDuration={NOTIFICATION_TIMEOUT}
    >
      <Alert
        variant={snackbar.severity}
        onClose={
          snackbar.closeable
            ? () => {
                closeSnackbar();
              }
            : undefined
        }
      >
        <div className="flex flex-col gap-d">
          <strong className="block">{snackbar.message}</strong>
        </div>
      </Alert>
    </Snackbar>
  );
}
